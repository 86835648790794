<template>
  <v-flex>
    <v-row>
      <template 
        v-for="(field,index) in fields"
      >
        <v-col
          :key="field.key"
          cols="12"
          sm="12" 
          md="12" 
          lg="6"
          xl="6" 
          :offset-xl="plotlyAlign(index)" 
          :offset-lg="plotlyAlign(index)"
        >
          <component
            :is="field.type"
            :key="field.key"
            :graph-title="field.label"
            :suggested-metric="suggestedMetric(field.key)"
            :success-metric="successMetric(field.key)"
          />
        </v-col>
      </template>
    </v-row>
  </v-flex>
</template>
<script>
import Attainment from "@/components/patient/Attainment";
import { get } from "lodash";
export default {
  name: "TargetAttainment",
  components: { Attainment },
  props: {
    schema: { type: Array, required: true, default: () => [] },
    suggestedMetrics: { type: Object, required: true, default: () => ({}) },
    successMetrics: { type: Object, required: true, default: () => ({}) },
  },
  data() {
    return {
      fields: [],
      val: "",
       isActive: false,
    };
  },
  mounted() {
    this.fields = this.schema;
  },
  methods: {
    plotlyAlign(num) {
     if(this.fields.length % 2 == 0){
      return 0;
     }
     if(num + 1 == this.fields.length){
      return 3;
     }
     return 0;
    },
    suggestedMetric(key) {
      return get(this.suggestedMetrics, key, 0);
    },
    successMetric(key) {
      return get(this.successMetrics, key, 0);
    },
  },
};
</script>