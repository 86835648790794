/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import axios from "axios";
import router from "../router/index";
import Auth from "@aws-amplify/auth"
const url =
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL;
   // eslint-disable-next-line no-undef
const client_id = process.env.VUE_APP_AWS_COGNITO_CLIENT_ID
const getToken = async () => {
  try {
    // Call Auth.federatedSignIn()
    const session = await Auth.currentSession();
    // If the authentication is successful, you can redirect or perform other actions
    return session.getAccessToken().jwtToken;
    // Redirect or perform other actions for authenticated users here
  } catch (error) {
    router.push({ path: "/auth/login" });
    // Handle specific error cases or show error messages to the user
  }
};
/**
 * Service helper class
 * @since 1.0.0
 */
export class Http {
  /**
   * extends a auth boolean param which will be passed on when invoking http helper class
   * @param {Object} status
   * @param {Boolean} status.auth
   */
  constructor(status) {
    this.is_auth = status && status.auth ? status.auth : false;
    /**
     * axios instance
     */
    this.instance = axios.create({
      /**
       * base URL from env
       */
      baseURL: url,
      /**
       * default headers
       */
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=utf-8",
      },
      /**
       * allocate timeout
       */
      // timeout: 20000,
    });
    return this.init();
  }

  init() {
    /**
     * request inperceptors **listners
     */
    // Request interceptor for API calls
    this.instance.interceptors.request.use(
      async (request) => {
        if (this.is_auth) {
          /**
           * customise your headers here
           */
          request.headers.authorization = await getToken();
          request.headers.cognito_client_app_id = client_id;

          return request;
        } else {
          return request;
        }
      },
      (error) => {
        return Promise.reject(error.response);
      }
    );
    // Response interceptor for API calls
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return this.instance;
  }
}
