<template>
  <v-layout row>
    <v-flex
      xs12
      pb-1
    >
      <div
        ref="plotContainer"
        class="plot-container"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import Plotly from "plotly.js-dist";
import dateUtils from "@/utils/index";

export default {
  name: "TimelineGraph",
  props: {
    visible: Boolean,
    graphData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentTimeTrace: {},
    };
  },
  computed: {
    fixedData() {
      let out = [];
       if(this.graphData.data){
      for (let trace of this.graphData.data) {
        if (trace.x) {
          for (const ind in trace.x) {
            trace.x[ind] = dateUtils.toLocalDateTimeString(trace.x[ind]);
          }
        }
        out.push(trace);
      }
       }
      out.push(this.currentTimeTrace);
      return out;
    },
    layout() {
      return this.graphData.layout;
    },
    config() {
      return this.graphData.config;
    },
  },
  watch: {
    data() {
      this.showPlot();
    },
    visible() {
      this.onResize();
    },
  },
  mounted() {
    this.showPlot();
  },
  methods: {
    onResize() {
      this.layout.width = this.$refs.plotContainer.offsetWidth;
      this.showPlot();
    },
    showPlot() {
      Plotly.react(
        this.$refs.plotContainer,
        this.fixedData,
        this.layout,
        this.config
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.plot-container {
  height: 550px;
}
</style>
