<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <b><u>Terms and Condition</u></b>
      </v-card-title>
      <v-container>
        <v-card-text>
          <v-card-title class="d-flex justify-center">
            PUMAS-AI, INC. SOFTWARE LICENSE AND SERVICES AGREEMENT
          </v-card-title>
          <hr>
          <br>

          This Agreement includes this cover sheet, the attached Software
          License and Services Terms and all exhibits attached hereto, and
          contains, among other things, warranty disclaimers, liability
          limitations and use limitations. Any different or additional terms or
          conditions of any related quote, purchase order, confirmation, or
          similar form, which conflict with the terms and conditions of this
          Agreement, shall have no force or effect.<br><br>
          Licensee understands and agrees to the terms of this Agreement and the
          undersigned officer of Licensee is duly authorized to sign this
          Agreement and bind Licensee to the terms contained herein.

          <v-card-title class="d-flex justify-center">
            SOFTWARE LICENSE AND SERVICES TERMS
          </v-card-title>
          <hr>
          <br><br>

          1. <b><u>Grant of License and Restrictions.</u></b> <br><br>

          (a). <u>License.</u> Subject to the terms hereof, payment of all fees,
          and any applicable user/use limitations, Pumas-AI grants Licensee and
          the Affiliates of Licensee set forth on <u>Exhibit A</u> (<b>“Authorized Affiliates”</b>) a nonexclusive, non-transferable, license to install and use the
          Pumas-AI software known as Lyv in object code form (the
          <b>“Software”</b>) on one server by an unlimited number of Licensee’s
          and its Authorized Affiliates’ employees or contractors (<b>“Authorized Users”</b>), solely for Licensee’s and Authorized Affiliates’ internal business
          purposes (the “License”). The license is an annual license renewed by
          mutual agreement of the parties as further described herein. Pumas-AI
          retains ownership of all copies of the Software and Licensee will
          maintain the copyright notice and any other trademarks or notices that
          appear on the Software on any copies and any media. Except for
          Authorized Affiliates, the license granted herein does not include the
          right for Licensee’s Affiliates to use the Software. Licensee shall be
          responsible for its Affiliates’ compliance with this Agreement. For
          purposes of this Agreement, <b>“Affiliate”</b> means, with respect to
          a party, any entity directly or indirectly controlling, controlled by,
          or under common control with, such party. For purposes of this
          Agreement, the term<b> “Controlled”</b> or <b>“Control”</b> (including
          the terms “Controlled by” and “under common Control with”) as used in
          this context, means the direct or indirect ability or power to direct
          or cause the direction of management policies of a person or entity or
          otherwise direct the affairs of such person or entity, whether through
          ownership of equity, voting securities, beneficial interest, by
          contract or otherwise. A party shall be presumed to control an entity
          if the party owns more than fifty percent (50%) of the outstanding
          voting equity interests of such entity. <br><br>

          (b). <u>Restrictions.</u> Licensee will not (and will not allow any
          third party or any of its Affiliates to) reverse engineer, decompile,
          or attempt to discover any source code or underlying ideas or
          algorithms of the Software. Licensee will not (and will not allow any
          third party or any of its Affiliates to): (i) copy, provide, lease,
          lend, disclose, resell, rent, use for timesharing or service bureau
          purposes, or otherwise use or allow others to use for the benefit of
          any third party, the Software (except as expressly and specifically
          authorized in writing by Pumas-AI); (ii) possess or use the Software,
          or allow the transfer, transmission, export, or re-export of the
          Software or portion thereof in violation of any export control laws or
          regulations administered by the U.S. Commerce Department, U.S.
          Treasury Department's Office of Foreign Assets Control, or any other
          government agency; (iii) disclose to any third party any benchmarking
          or comparative study involving the Software; or (iv) modify the
          Software. Prior to transferring or disposing of the Server containing
          any part of the Software related code, Licensee shall completely erase
          or delete any of Pumas-AI’s Software code installed on the Server,
          retaining no copy or copies for itself. All the limitations and
          restrictions on the Software in this Agreement also apply to
          Pumas-AI’s documentation for the Software provided to Licensee
          <b>(“Documentation”)</b>. <br><br>

          (c). <u>Implementation Services.</u>Pumas-AI shall provide the
          Software implementation services that are described on
          <u>Exhibit B</u> attached hereto (the
          <b>“Implementation Services”</b>) for the fees set forth on
          <u>Exhibit A</u> (<b>“Services Fee”</b>). If Licensee desires that the
          Software integrate with Licensee’s electronic health record system
          (<b>“EHR”</b>x`), Licensee is responsible for obtaining all required
          licenses, permissions and rights from its EHR provider to allow
          Pumas-AI to perform such integration. Pumas-AI shall have no liability
          whatsoever for Licensee’s failure to secure such licenses, permissions
          and rights. In the event that Licensee requests training or other
          professional services, Pumas-AI shall make such services available to
          Licensee under a separate written agreement and at Pumas-AI’s
          then-current rates. <br><br>

          (d). <u>Cooperation.</u> License acknowledges that the successful and
          timely rendering by Pumas-AI of implementation services, maintenance
          and support services shall require the good faith cooperation of
          Licensee. Pumas-AI’s provision of such services to Licensee is subject
          to the following terms and conditions: (i) Licensee shall provide
          Pumas-AI with access to Licensee’s personnel and to the Software and
          the Designated Operating Environment (as defined in Section 8(a)),
          including by remote access; (ii) Licensee shall take all steps
          necessary to carry out procedures for the rectification of errors or
          malfunctions within a reasonable time after such procedures have been
          received from Pumas-AI; and (iii) Licensee shall ensure that all of
          its personnel using the Software shall be reasonably trained in its
          use. Licensee hereby authorizes Pumas-AI to engage in remote access at
          any time Pumas-AI determines its reasonably necessary or appropriate
          to implement, support and maintain the Software. It is Licensee’s
          responsibility to configure its Designated Operating Environment and
          related network infrastructure to allow such access. If Pumas-AI is
          unable to access the Software as necessary or appropriate to perform
          under this Agreement, it may terminate this Agreement upon thirty (30)
          days’ written notice.<br><br>

          2. <b><u>Maintenance and Support Services.</u></b> During the Term of
          this Agreement and subject to payment of all applicable Subscription
          Fees (defined below), Pumas-AI will provide the maintenance and
          support services for the Software
          <b>(“Maintenance and Support Services”)</b> as and to the extent
          described in Pumas-AI’s standard maintenance and support terms that
          are attached hereto as <u>Exhibit C</u>.<br><br>
          3. <b><u>Fees and Payment. </u></b><br><br>

          <b> (a)</b> <u>Fees.</u> For the Initial Term (as defined below),
          Licensee will pay Pumas-AI a subscription fee in the amount set forth
          on <u>Exhibit A</u> (the <b> “Subscription Fee”</b> and, together with
          the Services Fee, the <b>“Fees”</b>). For each Renewal Term, Licensee
          will pay Pumas-AI the applicable Subscription Fee set forth in
          <u>Exhibit A</u>. Except as set forth in Section 8(a), all Fees paid
          by Licensee hereunder are nonrefundable.
          <br>
          <b>(b)</b> <u>Payment.</u> The Fees will be due in accordance with the
          payment schedule set forth in <u>Exhibit A</u>. All payments shall be
          made in U.S. funds by wire transfer to an account designated by
          Pumas-AI or by check drawn on a U.S. depository institution. All
          invoices are due and payable within thirty (30) days from the date of
          Licensee’s invoice. Any payments not made when due shall accrue
          interest from the date due until the date paid at one and one-half
          percent (1.5%) per month or, if less, the maximum per annum rate
          permitted by applicable law.
          <br>
          <b> (c)</b> <u>Taxes</u>. Licensee will pay all taxes imposed in
          conjunction with this Agreement, including, but not limited to, sales,
          use, excise, and similar taxes based on or measured by charges payable
          under this Agreement and imposed under authority of federal, state, or
          local taxing jurisdictions, but excluding foreign, federal, state, and
          local taxes on Pumas-AI's net income.<br><br>
          4. <b><u>Term; Termination; Breach.</u></b><br><br>
          <b> (a)</b> <u>Term</u>. This Agreement shall commence on the
          Effective Date. The initial term of this Agreement shall continue for
          the period set forth in<u>Exhibit A</u> (the <b>“Initial Term”</b>).
          Thereafter, subject to Licensee’s compliance with this Agreement and
          payment of Pumas-AI’s then-applicable Subscription Fees, this
          Agreement shall renew for successive one (1)-year terms (each, a
          <b>“Renewal Term”</b>) unless either party provides written notice of
          its intent to terminate to the other party at least thirty (30) days
          prior to the end of the Initial Term or the then-current Renewal Term.
          The Initial Term and all Renewal Terms shall be referred to as the
          “Term.”<br>
          <b> (b)</b> <u>Termination for Breach</u>. Should either party commit
          a material breach of this Agreement, the other party may, at its
          option, terminate this Agreement upon thirty (30) days' written notice
          of termination, which notice shall identify and describe the basis for
          such termination. If, prior to expiration of such period, the
          breaching party cures such breach to the reasonable satisfaction of
          the nonbreaching party, termination shall not take place.
          <br>
          <b> (c)</b> <u>Termination Upon Insolvency</u>. Either party may, at
          its option and without notice, terminate this Agreement, effective
          immediately, should the other party: (1) admit in writing its
          inability to pay its debts generally as they become due; (2) make a
          general assignment for the benefit of creditors; (3) institute
          proceedings to be adjudicated a voluntary bankrupt, or consent to the
          filing of a petition of bankruptcy against it; (4) be adjudicated by a
          court of competent jurisdiction as being bankrupt or insolvent; (5)
          seek reorganization under any bankruptcy act, or consent to the filing
          of a petition seeking such reorganization; or (6) have a decree
          entered against it by a court of competent jurisdiction appointing a
          receiver, liquidator, trustee, or assignee in bankruptcy or in
          insolvency covering all or substantially all of such party's property
          or providing for the liquidation of such party's property or business
          affairs
          <br>
          <b> (d)</b> <u>Termination for Change of Control</u>. Pumas-AI shall
          have the right to terminate this Agreement immediately by providing
          written notice to Licensee, in the event (a) Licensee assigns this
          Agreement without Pumas-AI’s prior written consent or (b) any change
          of Control of Licensee or an Authorized Affiliate.
          <br>
          <b> (e)</b> <u>Effect of Termination.</u>. Notwithstanding anything to
          the contrary in this Agreement, any termination of this Agreement
          shall not relieve either party hereto of any of its obligations or
          liabilities accrued hereunder prior to such termination. On
          termination for any reason: (a) all rights granted to Licensee under
          this Agreement shall cease; (b) Licensee shall immediately pay to
          Pumas-AI any sums due to Pumas-AI under this Agreement; and (c)
          Licensee shall immediately cease using and destroy all copies of the
          Licensed Software in Licensee’s possession, custody or control. All
          amounts due Pumas-AI and Sections 1(b), 4, 5, 6, 7, 8(b), 8(c), 9 and
          10 shall survive termination of this Agreement. Termination is not an
          exclusive remedy, and all other remedies will be available whether or
          not termination occurs. <br><br>
          5.<b><u>Confidentiality and Restrictive Covenant.</u></b><br><br>

          <b> (a)</b> <u>Confidentiality.</u>Each party agrees, except (i) as
          required as part of the performance of this Agreement or (ii) as
          permitted pursuant to any other agreement between the parties: (a) not
          to disclose any Confidential Information (as defined below) belonging
          to the other party to any person (other than on a need to know basis
          to such directors, employees, or other persons engaged in activities
          required for the performance of the obligations set out in this
          Agreement who have entered legally binding written obligations at
          least as protective as those set out in this Section 5); (b) not to
          use any Confidential Information belonging to the other party for any
          purpose other than in accordance with this Agreement; (c) to take all
          reasonable steps necessary to prevent the unauthorized disclosure
          and/or use of any Confidential Information belonging to the other
          party; and (d) to notify the other party promptly in writing in the
          event that a party becomes aware of an unauthorized disclosure of
          Confidential Information of the other party.<br>

          For the purposes of this Agreement,
          <b>“Confidential Information”</b> means any information received by
          one party or its Affiliates (the <b>“receiving party”</b>) from the
          other party (the <b>“disclosing party”</b>) and which the receiving
          party has been informed or has a reasonable basis to believe is
          confidential to the disclosing party, unless such information: (1) was
          known to the receiving party prior to receipt from the disclosing
          party; (2) was lawfully available to the public prior to receipt from
          the disclosing party; (3) becomes lawfully available to the public
          after receipt from the disclosing party, through no act or omission on
          the part of the receiving party; (4) corresponds in substance to any
          information received in good faith by the receiving party from any
          third party without restriction as to confidentiality; or (5) is
          independently developed by an employee or agent of the receiving party
          who has not received or had access to such information.
          <br>
          <b> (b)</b> <u>Data Protection</u>. Pumas-AI will not have access to
          any personally identifiable information of Licensee or Authorized
          Affiliates.<br>
          <b> (c)</b> <u>Aggregated and De-identified Data</u> In the course of
          Licensee’s or the Authorized Users’ use of the Software, Pumas-AI may
          obtain or derive aggregated or de-identified data related to Licensee
          or the Authorized Users’ use of the Software. Notwithstanding anything
          to the contrary in this Agreement, Licensee agrees that Pumas-AI is
          free to use this data for any lawful purpose, provided that all such
          data has been aggregated or de-identified so that neither Licensee nor
          any of its Authorized Users are individually identified.<br>
          <b> (d)</b> <u>Restrictive Covenant</u>. Licensee agrees that during
          the Term of this Agreement and thereafter, it shall not for itself or
          for the benefit of any third party directly or indirectly, use any
          Confidential Information of Pumas-AI, including without limitation,
          the Software or any related documentation, to develop or create
          software that competes with the Software licensed surender
          <br><br>
          6.<b><u>Proprietary Rights.</u></b><br><br>
          Except as expressly set forth herein, Pumas-AI alone (and its
          licensors, where applicable) do and will retain all intellectual
          property rights relating to the Software. If, in the course of
          performingunder this Agreement, Licensee provides Pumas-AI with any
          written comments, suggestions, or feedback regarding the Software,
          which do not include any Licensee Confidential Information
          (<b>“Feedback”</b>), Licensee hereby assigns all its rights, title,
          and interest in and to such Feedback to Pumas-AI. This Agreement is
          not a sale and does not convey to Licensee any rights of ownership in
          or related to the Software or any intellectual property rights.
          <br><br>
          7.<b><u>Indemnification.</u></b><br><br>
          <b> (a)</b> <u>Pumas-AI Indemnification</u>. Pumas-AI will defend any
          third party claim against Licensee and its Affiliates, and its and
          their directors, officers, employees and agents (collectively,
          <b>“Licensee Indemnified Parties”</b>) and pay damages and costs
          finally awarded against Licensee Indemnified Parties by a court of
          competent jurisdiction or that are included in a settlement approved
          by Pumas-AI, to the extent the Software (not including any third party
          software) is held to infringe a valid patent issued as of the
          Effective Date, copyright, or trade secret of any third party
          enforceable in the United States. This obligation is contingent upon:
          (i) Licensee’s prompt written notification to Pumas-AI of the claim;
          (ii) Licensee’s tender to Pumas-AI of the case and settlement
          negotiations; (iii) Pumas-AI’s sole control of the defense and
          settlement negotiations related to the claim; (iv) Licensee’s
          assistance (at Pumas-AI’s expense) in the defense or settlement of the
          claim; and (v) Licensee not making any admission prejudicial to the
          defense of the claim. Licensee agrees to take all reasonable steps to
          mitigate any losses. Pumas-AI will not be liable to a Licensee
          Indemnified Party if an infringement claim is based upon: (i) use of
          the Software in any combination with components not supplied or
          approved by Pumas-AI; (ii) modification of the Pumas-AI based on the
          direction or design requirements provided by a Licensee Indemnified
          Party or modifications made by anyone other than Pumas-AI including,
          without limitation, Licensee Indemnified Party created derivatives;
          (iii) use of Software where a non-infringing version or release of the
          Software with similar functionality which was offered by Pumas-AI to
          Licensee would have avoided the claim or infringement; (iv) use of
          third party software which is delivered or used in conjunction with
          the Software or; (v) use of the Software in violation of any of the
          terms of this Agreement. In addition to the above indemnity, if the
          Software (not including any third party software) is held to be
          infringing or where Pumas-AI believes it may be infringing, Pumas-AI
          may at its expense and option: (i) obtain for Licensee the right to
          continue using such Software; (ii) modify or replace such Software
          with non-infringing software of similar functionality; or (iii) if
          Pumas-AI determines that such remedies are not commercially
          reasonable, Licensee shall immediately cease using the Software and
          Pumas-AI shall terminate the License for such Software and return a
          portion of the Subscription Fee paid for the Software, as prorated
          over the lesser of: a) the length of the Term; b) the length of the
          relevant Renewal Term; or c) three years. The remedies stated in this
          Section 7(a) are the sole and exclusive remedies of Licensee
          Indemnified Parties with respect to any claims for intellectual
          property infringement related to the Software.<br>
          <b> (b)</b> <u>License Indemnification</u>.Licensee shall indemnify,
          defend and hold harmless Pumas-AI and its directors, officers,
          employees and agents from and against any and all losses, damages,
          liabilities, reasonable attorneys’ fees, court costs, expert’s fees
          and expenses, in each case, payable to third parties (collectively
          “Losses”), resulting or arising from or in connection with a Licensee
          Indemnified Party’s use of the Software (excluding those Losses for
          which Pumas-AI is responsible under Section 7(a)). <br><br>
          8.<b><u>Warranty; Disclaimer; Limitation of Liability. </u></b><br><br>
          <b> (a)</b> <u>Warranty</u>. Pumas-AI warrants that for a period of 30
          days after the date Licensee first uses the Software (the “Warranty
          Period”), the Software, as delivered to Licensee, will function in all
          material respects as described in the Documentation for the Software.
          Licensee shall promptly (but in no event later than ten days after
          discovering the same) provide Pumas-AI with written notice of any
          failure of the Software to perform as warranted. This warranty is
          expressly conditioned on Licensee’s compliance with each of the
          operating, security, and data-control procedures set forth in the
          Documentation as well as maintaining the Designated Operating
          Environment in the configuration specified in the Documentation.
          “Designated Operating Environment” means the hardware, software and
          network environment recommended by Licensor in the Documentation for
          the operation and use of the Software. In the event the Software fails
          to perform as warranted during the Warranty Period, Pumas-AI’s sole
          obligation and Licensee’s sole and exclusive remedy for such breach of
          warranty shall be to use commercially reasonable efforts to correct
          such failure. In the event Pumas-AI is unable to correct such failure
          by exercising reasonable commercial efforts, Pumas-AI shall terminate
          this Agreement and, as Licensee’s sole remedy for its inability to
          correct such failure, refund the Subscription Fee paid hereunder.
          <br>
          <b> (b)</b> <u>Disclaimer</u>. EXCEPT AS SET FORTH IN SECTION 8(A),
          PUMAS-AI DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, WITH
          RESPECT TO THE SOFTWARE AND ALL SERVICES PROVIDED HEREUNDER AND THERE
          ARE HEREBY EXCLUDED ALL WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
          A PARTICULAR PURPOSE. FURTHER, PUMAS-AI DOES NOT WARRANT RESULTS OF
          USE, INCLUDING THAT LICENSEE’S USE WILL RESULT IN LICENSEE’S
          COMPLIANCE WITH ANY LAW, RULE OR REGULATION, OR THAT THE SOFTWARE IS
          BUG FREE. LICENSEE ACCEPTS RESPONSIBILITY FOR THE SELECTION OF THE
          SOFTWARE TO ACHIEVE ITS INTENDED RESULTS.<b>NEITHER THE SOFTWARE, THE DOCUMENTATION, NOR ANY OUTPUT FROM THE
            SOFTWARE IS INTENDED TO BE A SUBSTITUTE FOR PROFESSIONAL MEDICAL
            ADVICE, DIAGNOSIS, OR TREATMENT. LICENSEE AGREES THAT LICENSEE AND
            ITS AFFILIATES SHALL NOT DIRECTLY OR INDIRECTLY REPRESENT THE
            SOFTWARE, DOCUMENTATION OR OUTPUT AS PROVIDING MEDICAL ADVICE. THE
            SOFTWARE DOES NOT RECOMMEND OR ENDORSE ANY SPECIFIC TESTS,
            PHYSICIANS, PRODUCTS, PROCEDURES, TREATMENTS, OPINIONS, OR OTHER
            INFORMATION THAT MAY BE MENTIONED, REFERENCED OR DESCRIBED IN THE
            SOFTWARE. RELIANCE ON ANY INFORMATION PROVIDED BY THE SOFTWARE,
            EITHER BY LICENSEE, ITS AFFILIATES OR THEIR RESPECTIVE PATIENTS IS
            SOLELY AT LICENSEE’S AND ITS AFFILIATES OWN RISK. LICENSEE AND ITS
            AFFILIATES ARE SOLELY RESPONSIBLE FOR ANY DECISIONS THEY MAKE WHILE
            USING THE SOFTWARE.</b>
          <br>
          <b> (c)</b> <u>Limitation of Liability.</u>.EXCEPT FOR CLAIMS UNDER
          SECTION 5 (CONFIDENTIALITY AND RESTRICTIVE COVENANT), NEITHER
          PUMAS-AI, LICENSEE OR AUTHORIZED AFFILIATES WILL HAVE ANY LIABILITY TO
          EACH OTHER FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT OR SPECIAL
          DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR THE USE
          OR PERFORMANCE OF THE SOFTWARE OR ANY SERVICES PROVIDED HEREUNDER,
          EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION
          APPLIES REGARDLESS OF WHETHER SUCH CLAIM IS BASED ON TORT, CONTRACT,
          WARRANTY, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER THEORY. EXCLUDING
          CLAIMS UNDER SECTIONS 5 (CONFIDENTIALITY AND RESTRICTIVE COVENANT) AND
          7 (INDEMNIFICATION), PUMAS-AI’S AGGREGATE LIABILITY TO LICENSEE, ITS
          AFFILIATES SHALL NOT EXCEED THE SUBSCRIPTION FEES PAID BY LICENSEE TO
          PUMAS-AI IN THE TWELVE MONTH PERIOD PRECEDING THE FIRST EVENT GIVING
          RISE TO THE CLAIM FOR DAMAGES. The parties are both sophisticated
          entities. The prices paid, the warranties, warranty disclaimers,
          limitations of liability, remedy limitations, and all other provisions
          of this Agreement, were negotiated to reflect and support an informed
          and voluntary allocation of risks between Pumas-AI and Licensee, and
          both parties waive all protections of any trade practices statutes.

          <br><br>
          9.<b><u>Identification </u></b><br><br>
          Pumas-AI may use Licensee’s name and logo in client listings, but any
          other use of Licensee’s name or logo required Licensee’s prior
          consent.

          <br><br>
          10.<b><u>Miscellaneous. </u></b><br><br>
          <b> (a)</b> <u>Force Majeure</u>. Neither party shall be liable or
          deemed in default for any delay or failure in performance hereunder
          (other than for payment of monies owed) resulting from any cause
          beyond its reasonable control. ><br>
          <b> (b)</b> <u>Governing Law</u>. This Agreement shall be governed by
          and construed in accordance with the laws of the State of Maryland,
          U.S.A., without reference to conflicts of laws provisions and, as to
          matters affecting copyrights, trademarks and patents, by U.S. federal
          law. Any dispute with arising out of or relating to this Agreement
          shall be brought and heard solely and exclusively in the state or
          federal courts of competent jurisdiction in the State of Maryland,
          U.S.A. In such event, each party consents to the sole and exclusive in
          personam jurisdiction and venue of such courts. Each party agrees that
          service of process upon such party in any such action may be made if
          delivered in person, by courier service, by telegram, or by first
          class mail, and shall be deemed effectively given upon receipt.
          <br>
          <b> (c)</b> <u>Assignment</u>.Licensee shall not assign this Agreement
          without the prior written consent of Pumas-AI, which it may withhold
          in its sole discretion. No assignment shall relieve Licensee of its
          obligations under this Agreement. Any prohibited assignment is void.
          Any merger, consolidation or change of ownership of a controlling
          voting interest in a party shall be considered to affect an assignment
          for purposes of this paragraph

          <br>
          <b> (d)</b> <u>Invalidity</u>. If any parts or part of this Agreement
          are held to be invalid, the remaining parts of the Agreement will
          continue to be valid and enforceable.

          <br>
          <b> (e)</b> <u>Waiver</u>. The failure to enforce any right or
          provision herein shall not constitute a waiver of that right or
          provision. Any waiver of a breach of a provision shall not constitute
          a waiver of any subsequent breach of that provision.
          <br>
          <b> (f)</b> <u>Entire Agreement</u>. This Agreement (including all
          Exhibits) contains the complete understanding of the parties with
          respect to the subject matter hereof and supersede any prior written
          or oral agreements regarding such subject matter. In making this
          Agreement, neither party relies on any promise, action or statement
          made by the other party, other than those contained in this Agreement
          and its exhibits. In the event of a conflict between the provisions of
          the exhibits to this Agreement and the provisions of this Agreement
          itself, the conflicting provision of the Agreement shall control over
          the language in the exhibit, unless otherwise agreed by the parties.
          This Agreement may be executed in two (2) or more counterparts, each
          of which shall be deemed an original, but all of which together shall
          constitute one and the same instrument.

          <br>
          <b> (g)</b> <u>Amendment</u>. No amendment, waiver, alteration or
          modification of any of the provisions of this Agreement will be
          binding unless it is in writing and signed by a duly authorized
          representative of each party
          <br>
          <b> (h)</b> <u>Notices</u>. All notices, requests, demands, or other
          communications relating to the other party's failure to perform or
          which otherwise affect either party's rights under this Agreement will
          be deemed properly given when furnished by receipted hand-delivery to
          the other party, deposited with an express courier, or deposited with
          the U.S. Postal Service (postage prepaid, certified mail, return
          receipt requested)<br><br>
          <div class="d-flex justify-center">
            <u>Exhibit C</u>
          </div>
          <v-card-title class="d-flex justify-center">
            SUPPORT AND MAINTENANCE TERMS
          </v-card-title>
          <hr>
          <br>
          Pumas-AI shall provide the following Maintenance and Support Services
          with respect to the Software licensed under the Software License and
          Services Agreement, 2020 by and between Licensee and Pumas-AI.
          Capitalized terms not defined in Section 4 below shall have the same
          meaning as in the Agreement. Subject to payment of applicable
          Subscription Fees, Maintenance and Support Services shall be provided
          during the Term of the Agreement.<br><br>
          1. <b>SUPPORT SERVICES</b> Maintenance and Support Services consist of
          (a) Error Correction and Telephone Support (provided to three
          designated Licensee technical support contacts) concerning the use of
          the then-current release of the Software and the Previous Sequential
          Release, (b) E-mail Support, (c) Web Support, and (d) the Software
          Updates. <br><br>
          2. <b>PRIORITY LEVELS</b> Pumas-AI shall correct any Error reported by
          Licensee in the current release of the Software and the Previous
          Sequential Release of the Software in accordance with the priority
          level reasonably assigned to such Error by Pumas-AI when taking into
          consideration the needs and business of Licensee. <br><br>

          <b>(a)</b> Priority A Errors - Pumas-AI shall respond within one (1)
          hour and promptly commence the following procedures: (i) assign
          Pumas-AI engineers to correct the Error; (ii) notify Licensee
          management that such Errors have been reported and of steps being
          taken to correct such Error(s); (iii) provide Licensee with periodic
          reports on the status of the corrections; and (iv) use best efforts on
          a 24/7 basis to provide Licensee with a Workaround or Fix within
          twenty-four (24) hours.<br>

          <b>(b)</b> Priority B Errors - Pumas-AI shall respond within one (1)
          Business Day and promptly commence the following procedures: (i)
          assign Pumas-AI engineers to correct the Error; (ii) notify Licensee
          management that such Errors have been reported and of steps being
          taken to correct such Error(s); (iii) provide Licensee with periodic
          reports on the status of the corrections; and (iv) use best efforts on
          a 24/7 basis to provide Licensee with a Workaround or Fix within
          forty-eight (48) hours.<br>

          <b>(c)</b> Priority C Errors - Pumas-AI shall respond within two (2)
          Business Days and promptly commence the following procedures: (i)
          assign Pumas-AI engineers to correct the Error; (ii) provide Licensee
          with periodic reports on the status of the corrections; and (iii) use
          reasonable efforts to provide Licensee with a Workaround or Fix within
          five (5) Business Days.<br>

          <b>(d)</b> Priority D Errors - Pumas-AI shall respond within five (5)
          Business Days and as soon as reasonably practical commence the
          following procedures: (i) assign Pumas-AI engineers to correct the
          Error; (ii) provide Licensee with periodic reports on the status of
          the corrections; and (iii) use reasonable efforts to provide Licensee
          with a Workaround or Fix within ten (10) Business Days; provided,
          however, that Pumas-AI may include the Fix for the Error in the next
          release of the Software.<br><br>
          If Pumas-AI believes that a problem reported by Licensee may not be
          due to an Error in the Software, Pumas-AI will so notify Licensee. At
          that time, Licensee may (1) instruct Pumas-AI to proceed with problem
          determination at its possible expense as set forth below, or (2)
          instruct Pumas-AI that Licensee does not wish the problem pursued at
          its possible expense. If Licensee requests that Pumas-AI proceed with
          problem determination at its possible expense and Pumas-AI determines
          that the error was not due to an Error in the Software, Licensee shall
          pay Pumas-AI, at Pumas-AI’s then-current and standard consulting
          rates, for all work performed in connection with such determination,
          plus reasonable related expenses incurred therewith. Licensee shall
          not be liable for (i) problem determination or repair to the extent
          problems are due to Errors in the Software; or (ii) work performed
          under this paragraph in excess of its instructions; or (iii) work
          performed after Licensee has notified Pumas-AI that it no longer
          wishes work on the problem determination to be continued at its
          possible expense (such notice shall be deemed given when actually
          received by Pumas-AI). If Licensee instructs Pumas-AI that it does not
          wish the problem pursued at its possible expense or if such
          determination requires effort in excess of Licensee’s instructions,
          Pumas-AI may, at its sole discretion, elect not to investigate the
          error with no liability therefor.

          <br><br>
          3. <b>EXCLUSIONS</b>. Pumas-AI shall have no obligation to support:
          (i) any Software that has been modified or altered by a third party
          without the consent of Pumas-AI; (ii) any Software that is not the
          then-current release or immediately Previous Sequential Release; (iii)
          Software problems caused by Licensee’s use of the Software other than
          as specified in Pumas-AI’s user manual or other causes beyond the
          control of Pumas-AI; or (iv) the Software installed on any hardware
          that is not supported by Pumas-AI.<br><br><br>

          <b><u>DEFINITIONS</u></b><br><br>
          <b>(1).</b> “E-mail support” means the ability to make requests for
          technical support assistance by e-mail at any time concerning the
          installation and use of the then-current release of the Software and
          the Previous Sequential Release.
          <br>
          <b>(2).</b> “Error” means an error in the Software which degrades such
          the Software as compared to the Specifications or prevents the
          Software from performing in accordance with any of its documentation.
          <br>
          <b>(3).</b> “Error Correction” means the correction of Errors.
          <br>
          <b>(4).</b> “Fix” means the repair or replacement of object or
          executable code versions of the Software or documentation to remedy an
          Error.
          <br>
          <b>(5).</b> “Previous Sequential Release” means the release of the
          Software which has been replaced by a subsequent release of the
          Software. Notwithstanding anything else, a Previous Sequential Release
          will be supported by Pumas-AI only for a period of twelve (12) months
          after release of the subsequent release.
          <br>
          <b>(6).</b> “Priority A Error” means an Error that renders the
          Software inoperative.
          <br>
          <b>(7).</b> “Priority B Error” means an Error that substantially
          degrades the performance of the Software or materially restricts
          Licensee’s use of such the Software.
          <br>
          <b>(8).</b> “Priority C Error” means an Error that causes only a minor
          impact on the Licensee’s use of the Software and does not
          substantially impact Licensee’s business.
          <br>
          <b>(9).</b> “Priority D Error” means an Error that does not cause an
          impact on the Licensee’s use of the Software and does not
          substantially impact Licensee’s business, but which prevents the
          Software from performing in accordance with the Specifications.
          <br>
          <b>(10).</b> “Telephone Support” means technical support telephone
          assistance between 7:00AM and 7:00PM Eastern Time on Pumas-AI’s
          regular business days concerning the installation and use of the then
          current release of the Software and the Previous Sequential Release.

          <br>
          <b>(11).</b> “Update” means any new version or release of the
          Software, or patches, which are necessary to Fix or correct Errors, or
          which Pumas-AI in its discretion makes generally available to its
          other licensees without additional charge.
          <br>
          <b>(12).</b> “Web Support” means information available on the World
          Wide Web, including frequently asked questions, the Software
          documentation and bug reporting.
          <br>
          <b>(13).</b> “Workaround” means a change in the procedures followed or
          data supplied by Licensee to avoid an Error without substantially
          impairing Licensee’s use of the Software.
          <br>
          <v-checkbox
            v-model="checked"
            label="I Agree"
          />
          <v-btn
            id="navigate"
            color="#EF515B"
            outlined
            :disabled="checked === false"
            @click="agreed"
          >
            okay
          </v-btn>
        </v-card-text>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import { Main } from "@/services/modules";
import Error_Message from "@/utils/notification";
export default {
  data() {
    return {
      checked: false,
    };
  },
  mounted() {
    if (this.$store.getters.agreementAccepted) {
      this.$router.push({ name: "Condition" });
    }
  },
  methods: {
    agreed() {
      Main.acceptedTermsAndCondition({})
        .then(() => {
          this.$store.dispatch("agreedTermsCondition");
          this.$router.push({ name: "Condition" });
        })
        .catch((error) => {
          // this.setSnack(error);
           this.$store.commit(
          "snackbar/setSnack",
           Error_Message.display_message(error.data.message)
        );
        });
    },
  },
};
</script>
<style></style>
