<template>
  <v-layout>
    <div
      v-for="(sub_input, j) in field.sub_fields"
      :key="j"
    >
      <v-flex
        xs12
        :class="j > 0 ? 'pl-5' : ''"
      >
        <NumberInput
          v-if="patientDetails[field.key]"
          :label="j == 0 ? field.label : ''"
          :value="patientDetails[field.key][sub_input.key]"
          :append-text="sub_input.label"
          disabled
        />
        <NumberInput
          v-else
          :label="j == 0 ? field.label : ''"
          value=""
          :append-text="sub_input.label"
          disabled
        />
      </v-flex>
    </div>
  </v-layout>
</template>

<script>
import NumberInput from "@/components/form/NumberInput";
export default {
  name: "SubFields",
  components: {
    NumberInput,
  },
  props: {
    patientDetails: { type: Object, default: () => ({}) },
    subFields: { type: Array, default: () => [] },
    field: { type: Object, default: () => ({}) },
  },
  created() {},
};
</script>
