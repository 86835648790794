<template>
  <section class="main-view">
    <div class="container-scroller">
      <LyvHeader />
      <!-- <b-overlay spinner-type="grow" spinner-variant="inverse-primary" :show="loader" rounded="sm" class="container-fluid page-body-wrapper"> -->
      <div class="main-panel">
        <div class="content-wrapper background">
          <router-view />
        </div>
        <!-- content wrapper ends -->
        <LyvFooter />
      </div>
      <!-- main panel ends -->
      <!-- </b-overlay> -->
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import LyvHeader from "./partials/LyvHeader";
import LyvFooter from "./partials/LyvFooter";
export default {
  name: "Layout",
  components: {
    LyvHeader,
    LyvFooter,
  },
};
</script>

<style>
.background {
  min-height: 90vh;
}
.main-view {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px; /* height of your footer */
}
</style>
