<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex justify-end">
        <v-btn
          color="#EF515B"
          outlined
          text
          @click="onAddFile()"
        >
          Upload File
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="files"
        :items-per-page="5"
        class="elevation-1"
      />
    </v-card>
  </v-container>
</template>

<script>
import { Main } from "@/services/modules";

export default {
  name: "FileList",

  data() {
    return {
      files: [],
      headers: [
        { text: "Title", value: "title" },
        { text: "Reference ID", value: "code" },
        {
          text: "File name",
          value: "filename",
        },
        { text: "Status", value: "status" },
      ],
    };
  },

  mounted() {
    this.getFiles();
  },

  methods: {
    onAddFile() {
      this.$router.push({ name: "AddFile" });
    },
    async getFiles() {
      const response = await Main.getAllFiles();
      const files = response.data.data;
      for (let i = 0; i < files.length; i++) {
        const element = files[i];
        this.files.push({
          filename: element.name,
          code: element.code,
          title: element.title,
          status: element.status,
        });
      }
      // console.log(this.files);
    },
  },
};
</script>
