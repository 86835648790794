<template>
  <div>
    <Card
      title="Dose Recommendation"
      class="mb-12"
      :loading="saving || loading"
    >
      <DosageInfo
        v-if="!loading && schema"
        :general-details="patientDetails.generalDetails"
        :dose-details="patientDetails.dosedetails"
        :suggested-dose-details="patientDetails.suggested_dosedetails"
      />      
      <ModifyDosage
        v-if="!loading && schema.length > 0"
        ref="modifyDosage"
        :general-details="patientDetails.generalDetails"
        :dose-details="patientDetails.dosedetails"
        :suggested-dose-details="patientDetails.suggested_dosedetails"
        :enable-navigation="enableNavigation"
        :saving="saving"
        :allow-user-to-edit-or-not="allowUserToEditOrNot"
        :schema="_.find(schema, { type: 'modifyDose' }).fields"
        v-on="$listeners"
        @commonFunction="commonFunction"
        @change="onChange($event, field)"
      />
    </Card>
    <Card
      class="mt-n8"
      title="Target Attainment"
    >
      <TargetAttainment
        v-if="!loading && schema.length > 0"
        :suggested-metrics="patientDetails.suggestedSuccessMetrics"
        :success-metrics="patientDetails.successMetrics"
        :schema="_.find(schema, { type: 'metrics' }).fields[0].fields"
      />
      <hr>
      <Metrics
        v-if="!loading && schema.length > 0"
        :success-metrics="patientDetails.successMetrics"
        :suggested-metrics="patientDetails.suggestedSuccessMetrics"
        :pk-parameters="patientDetails.pkparameters"
        :schema="_.find(schema, { type: 'metrics' }).fields[1].fields"
      />
    </Card>
    <Card
      class="text-capitalize"
      :title="drugname + ' PK Parameters'"
    >
      <PKParameters
        v-if="!loading && schema.length > 0"
        :success-metrics="patientDetails.successMetrics"
        :pk-parameters="patientDetails.pkparameters"
        :schema="_.find(schema, { type: 'parameters' }).fields"
        :drugname="drugname"
      />
    </Card>
  </div>
</template>

<script>
import Card from "@/components/ui/Card";
import DosageInfo from "@/components/patient/DosageInfo";
import ModifyDosage from "@/components/patient/ModifyDosage";
import TargetAttainment from "@/components/patient/TargetAttainment";
import PKParameters from "@/components/patient/PKParameters";
import Metrics from "@/components/patient/Metrics";
import _ from "lodash";
// import { Http } from "@/services/service";
// import { orderBy } from "lodash";

export default {
  name: "DoseRecommendationTab",

  components: {
    PKParameters,
    TargetAttainment,
    DosageInfo,
    ModifyDosage,
    Card,
    Metrics,
  },

  props: {
    loading: { type: Boolean, required: true, default: false },
    saving: { type: Boolean, required: true, default: false },
    enableNavigation: { type: Boolean, required: true, default: false },
    patientDetails: { type: Object, required: true, default: () => ({}) },
    allowUserToEditOrNot: { type: Boolean, required: false, default: false },
    schema: { type: Array, required: true, default: () => [] },
    drugname: { type: String, required: true, default: "" },
  },
  computed: {
    _() {
      return _;
    },
  },
  methods: {
    commonFunction(v, key) {
      this.$emit("commonFunction", v, key);
    },
    triggerValidate() {
      this.$refs.modifyDosage.validate = false;
      this.$nextTick(() => {
        this.$refs.modifyDosage.validate = true;
      });
    },
  },
};
</script>

<style scoped>
hr{
    border: 0.01px solid #404040;
}
</style>
