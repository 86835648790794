import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";
import layout from "../layout";
import PageNotFound from "@/pages/PageNotFound";
import Login from "@/pages/auth-pages/login";
import FileList from "@/pages/manage/file/FileList";
import AddFile from "@/pages/manage/file/AddFile";
import Treatment from "@/pages/manage/treatment/index";
import Manage from "@/pages/manage/index";
import Dashboard from "@/pages/dashboard/index";
import Profile from "@/pages/profile-page/Profile";
import Patient from "@/pages/patient/index";
import PatientDetail from "@/pages/patient/PatientDetail";
import Agreement from "@/pages/Agreement";
import Auth from "@aws-amplify/auth"
import store from "@/store/index";
import { Hub } from "@aws-amplify/core"

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
Vue.use(Router);

getUser().then((user) => {
  return user;
});

function getUser() {
  return Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUser', data);
      return data;
    }
  }).catch(() => {
    store.commit('setUser', null);
    return null;
  });
}
function filterErrorMessage(message) {
  return 'PreAuthentication' + message.split('+').join(' ').split('PreAuthentication').pop().replace(/\s+/g, " ")
}

Hub.listen("auth", async (data) => {
  if (data.payload.event === 'signIn_failure') {
    store.commit("snackbar/setSnack", filterErrorMessage(data.payload.data.message));
  }
});

const routes = [
  { path: '/callback', component: layout, redirect: "/conditions" },
  {
    path: "/",
    redirect: "/conditions",
    component: layout,
  },
  {
    path: "/auth",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      }
    ],
  },
  {
    path: "/file",
    component: layout,
    meta: {
      requiresAuth: true,
      allowedRoles: ["admin"],
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "FileList",
        component: FileList,
      },
      {
        path: "/new-file",
        name: "AddFile",
        component: AddFile,
      },
    ],
  },
  {
    path: "/schema",
    component: layout,
    meta: {
      requiresAuth: true,
      allowedRoles: ["admin"],
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "Schema",
        component: Treatment,
      },
    ],
  },
  {
    path: "/manage",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "Manage",
        component: Manage,
      },
    ],
  },
  {
    path: "/conditions",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "Condition",
        component: Dashboard,
      },
      {
        path: "",
        name: "Profile",
        component: Profile,
      },
    ],
  },
  {
    path: "/:condition/treatments",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "Treatment",
        component: Patient,
      },
    ],
  },
  {
    path: "/:condition/treatments/:treatment/patientList/:pageNo",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "PatientList",
        component: Patient,
      },
    ],
  },
  {
    path: "/:condition/treatments/:treatment/newPatient",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "NewPatient",
        component: PatientDetail,
      },
    ],
  },
  {
    path: "/:condition/treatments/:treatment/patientStatus/:mrn",
    component: layout,
    meta: {
      requiresAuth: true,
      requiresAgreement: true,
    },
    children: [
      {
        path: "",
        name: "EditPatient",
        component: PatientDetail,
      },
    ],
  },

  {
    path: "/user",
    component: layout,
    children: [
      {
        path: "terms-and-condition",
        name: "acceptedTermsAndCondition",
        meta: {
          requiresAuth: true,
          requiresPasswordChange: true,
        },
        component: Agreement,
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];


const router = new VueRouter({
  mode: "history",
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let user = await getUser();
    if (!user) {
      return next({
        path: 'auth/login'
      });
    }
    return next()
  }
  return next()
});

export default router;
