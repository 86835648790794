import { kebabCase } from "lodash";
import moment from "moment";
import constant from "../config/constant.json"

export default {
  props: {
    label: { type: String, default: "" },
    value: { default: null },
    rules: { type: Array, default: () => [(value) => !!value || constant.validate.content] },
    date: {
      type: Array,
      default: () => [
        (value) => !!value || constant.validate.content,
        (value) =>
          moment(value, "YYYY-MM-DD", true).isValid() || constant.dateValidate.content,
        (value) =>
          moment(value, "YYYY-MM-DD") < moment(new Date(), "YYYY-MM-DD") ||
          constant.dateValidate.content,
      ],
    },
    required: { type: Boolean, default: false },
    validate: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    message: { type: String, default: "" },
    errorMessage: { type: String, default: "" },
  },

  watch: {
    validate(value) {
      if (value) {
        this.$emit("change", this.value);
      }
    },
  },

  computed: {
    id() {
      return `id-${kebabCase(this.label)}`;
    },
    applyRules() {
      return this.required ? this.rules : [];
    },
    dateRules() {
      return this.required ? this.date : [];
    },
    isNotValid() {
      return this.validate && this.required && !this.value;
    },
  },
};
