const state = {
    user: null
}

const mutations = {
    setUser(state, user) {
        state.user = user
    },
}
const getters = {
    isSignIn: (state) => {
        return state.user !== null
    },
    userInfo: (state) => {
        return state.user;
    },
}
export default {
    state,
    getters,
    mutations,
};