import Vue from "vue";
import Vuex from "vuex";
import auth_store from "./store_modules/auth_store";
import snackbar from "./store_modules/snackbar";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    snackbar,
    auth_store
  },
});
