<template>
  <v-container>
    <v-alert
      v-if="error"
      dense
      outlined
      type="error"
    >
      {{ errorMessage }}
    </v-alert>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span>Upload File</span>
      </v-card-title>
      <v-form>
        <v-row class="px-4">
          <v-col md="4">
            <v-text-field
              v-model="title"
              label="Title"
              required
            />
          </v-col>
          <v-col md="4">
            <v-select
              v-model="selectedCondition"
              class="text-capitalize"
              :items="conditions"
              item-text="name"
              item-value="id"
              label="Condition"
              @change="getTreatmentsByCondition"
            />
          </v-col>
          <v-col md="4">
            <v-select
              v-model="selectedTreatment"
              class="text-capitalize"
              :items="treatments"
              item-text="name"
              item-value="_id"
              label="Treatment"
            />
          </v-col>
        </v-row>
        <v-row class="px-4">
          <v-col md="12">
            <div>
              Attach (CSV) File Here:
              <v-file-input
                v-model="selectedFile"
                accept="text/csv, application/vnd.ms-excel"
                :rules="rules"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            align="left"
            justify="space-around"
          >
            <v-btn
              class="ma-5"
              color="#EF515B"
              outlined
              text
              :disabled="!enableUpload"
              @click="onUpload"
            >
              Upload File
            </v-btn>
            <v-btn
              depressed
              @click="goBack"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { Main } from "@/services/modules";
// import Snackbar from "../../components/ui/Snackbar";
import { mapMutations } from "vuex";
import constant from "@/config/constant"
import Error_Message from "@/utils/notification";

export default {
  name: "AddFile",

  data() {
    return {
      title: null,
      selectedCondition: null,
      selectedTreatment: null,
      selectedFile: null,
      conditions: [],
      treatments: [],
      allowedFileTypes: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
        "application/vnd.ms-excel",
      ],
      rules: [
        (value) =>
          !value ||
          this.allowedFileTypes.includes(value.type) ||
          constant.addFile.content_file,
      ],
      error: false,
      errorMessage: "",
    };
  },

  computed: {
    enableUpload() {
      return (
        this.title &&
        this.selectedCondition &&
        this.selectedTreatment &&
        this.allowedFileTypes.includes(this.selectedFile.type)
      );
    },
  },

  mounted() {
    this.getAllConditon();
  },

  methods: {
    async getAllConditon() {
      const response = await Main.getAllConditon();
      this.conditions = response.data.data;
    },
    async getTreatmentsByCondition(conditionId) {
      const response = await Main.getAllTreatment(conditionId);
      this.treatments = response.data.data;
    },
    onUpload() {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("conditionId", this.selectedCondition);
      formData.append("treatmentId", this.selectedTreatment);
      formData.append("file", this.selectedFile, this.selectedFile.name);
      Main.saveFile(formData)
        .then((response) => {
          this.setSnack(response.data.message);
          this.$router.push({ name: "FileList" });
        })
        .catch((error) => {
          this.$store.commit(
          "snackbar/setSnack",
           Error_Message.display_message(error.data.message)
        );
          this.errorMessage = error.data.message;
          this.error = true;
          setTimeout(function () {
            this.error = false;
          }, 4000);
        });
    },
    ...mapMutations({
      setSnack: "snackbar/setSnack",
    }),
    goBack() {
      if (confirm(constant.addFile.content)) {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style>
.header {
  background-color: #90939d;
}
.v-list-item__title {
  text-transform: capitalize;
}
</style>
