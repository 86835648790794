<template>
  <v-container>
    <v-row>
      <v-col
        sm="12"
        lg="3"
      >
        <v-card
          class="mx-auto"
          outlined
          :to="`/file`"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline">
                File
              </div>
            </v-list-item-content>
            <img
              class="mangae_image_30"
              src="@/assets/files_icon.png"
              alt=""
              srcset=""
            >
          </v-list-item>
        </v-card>
      </v-col>
      <v-col
        sm="12"
        lg="3"
      >
        <v-card
          class="mx-auto"
          outlined
          :to="`/schema`"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline">
                Modules
              </div>
            </v-list-item-content>
            <img
              class="mangae_image_40"
              src="@/assets/module.png"
              alt=""
              srcset=""
            >
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Manage",
  data: () => ({
    show: false,
  }),
};
</script>
<style>
.mangae_image_40 {
  width: 40px;
}
.mangae_image_30 {
  width: 30px;
}
</style>
