import { DateTime } from "luxon";
import config from "../config/instance";

const millisToFormattedDate = (value) => {
  if (value) {
    return DateTime.fromMillis(value, {
      zone: config.date_time.events.zone,
    }).toFormat(config.date_time.events.DEFAULT_DATE_FORMAT);
  }
};

const isoToFormattedDate = (value) => {
  if (value) {
    return DateTime.fromISO(value).toFormat(
      config.date_time.events.DEFAULT_DATE_FORMAT
    );
  }
};

const millisToFormattedTime = (value) => {
  if (value) {
    return DateTime.fromMillis(value, {
      zone: config.date_time.events.zone,
    }).toFormat(config.date_time.events.DEFAULT_TIME_FORMAT);
  }
};

const dobToFormatted = (value) => {
  if (value) {
    return DateTime.fromFormat(value, {
      zone: config.date_time.events.zone,
    }).diffNow("years");
  }
};
const maybeto12hr = (time, { shouldConvert }) => {
  const match = time.match(/^(\d\d):(\d\d)/);
  let hours = match[1];
  const minutes = match[2];
  let timeUnit = "";
  if (shouldConvert) {
    hours = parseInt(hours);
    timeUnit = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
  }
  return {
    time: `${hours}:${minutes}`,
    unit: timeUnit,
  };
};

const toLocalDateTimeString = (datetime) => {
  datetime = new Date(datetime);
  return (
    datetime.getFullYear() +
    "-" +
    ("0" + (datetime.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + datetime.getDate()).slice(-2) +
    "T" +
    ("0" + datetime.getHours()).slice(-2) +
    ":" +
    ("0" + datetime.getMinutes()).slice(-2)
  );
};

const prettifyDate = (datetime, { ampm }) => {
  datetime = new Date(datetime);
  const date =
    datetime.getFullYear() +
    "-" +
    ("0" + (datetime.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + datetime.getDate()).slice(-2);
  let time =
    ("0" + datetime.getHours()).slice(-2) +
    ":" +
    ("0" + datetime.getMinutes()).slice(-2);

  let { time: ftime, unit } = maybeto12hr(time, { shouldConvert: ampm });

  return {
    datetime: datetime,
    date: date,
    time: ftime,
    timeunit: unit,
  };
};

const toLocalDateTime = (datetime) => {
  return new Date(datetime);
};

const toUTC = (object) => {
  if (object instanceof Date) {
    return object.valueOf();
  }
  if (object instanceof Object) {
    for (const key of Object.keys(object)) {
      object[key] = toUTC(object[key]);
    }
  }
  return object;
};

export default {
  millisToFormattedDate,
  isoToFormattedDate,
  millisToFormattedTime,
  dobToFormatted,
  maybeto12hr,
  toLocalDateTimeString,
  prettifyDate,
  toLocalDateTime,
  toUTC,
};
